import React from 'react'
import * as styles from './BioElement.module.css'

const BioElement = ({ bioElement }) => {
    const { who, what, where, when } = bioElement;

    return (
        <div className={styles.bioElement}>
            <span><b>{who}</b></span><br />
            <span>{what}</span><br />
            <span>{where}</span><br />
            <span><i>{when}</i></span><br />
        </div>
    )
}

export default BioElement