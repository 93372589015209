import React, { useContext } from 'react'
import PageLayout from '../components/PageLayout';
import BioElement from '../components/BioElement';
import { bioEducation, bioEntries, MY_LINKS } from "../context/consts";
import { splitAndSpan } from "../utils/parsers";
import * as styles from './about.module.css'
import BorderedLabel from "../components/BorderedLabel";
import classNames from "classnames";
import { StaticImage } from "gatsby-plugin-image";
import { GlobalStateContext } from "../context/GlobalContextProvider"
import Awards from '../components/Awards';
import Seo from '../components/Seo';


const AboutPage = () => {
    const { theme } = useContext(GlobalStateContext)


    return (
        <PageLayout firstHead="Call Me" secondHead="Gab">
            <div className={styles.aboutContainer}>
                <StaticImage placeholder="blurred" layout="fullWidth" objectFit="scale-down" objectPosition="0% 100%" src="./../images/gabrielefaoro.png" className={styles.bigImage} alt="Gabriele Faoro" />
                <div className={styles.bioCnt}>
                    <p>I am an industrial designer, but also a teacher, a writer, a drummer and a spare time artist. I live my days trying to converge all these personalities in one single body, without fainting.</p>
                    <div className={styles.bioGrid}>
                        <div className={styles.experienceBio}>
                            <div className={styles.labelCnt}>
                                <h4 className={styles.spannedH3}>{splitAndSpan("EXPERIENCE")}</h4>
                            </div>
                            {bioEntries.map((b, i) => <BioElement key={i} bioElement={b} />)}

                        </div>
                        <div className={styles.educationBio}>
                            <div className={styles.labelCnt}>
                                <h4 className={styles.spannedH3}>{splitAndSpan("EDUCATION")}</h4>
                            </div>
                            {bioEducation.map((b, i) => <BioElement key={i} bioElement={b} />)}
                            <div className={styles.downloadLink}>
                                <a href={MY_LINKS.CV}><BorderedLabel firstLabel="download" secondLabel="cv" /></a>
                            </div>
                        </div>
                    </div>
                    <div className={styles.labelCnt}>
                        <h4 className={classNames(styles.spannedH3, styles.halfWidth)}>{splitAndSpan("AWARDS")}</h4>
                    </div>
                    <div className={styles.labelCnt}>
                        <Awards />
                    </div>
                </div>
            </div>
            <Seo subTitle="About" />
        </PageLayout>
    )
}

export default AboutPage