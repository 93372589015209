// extracted by mini-css-extract-plugin
export var aboutContainer = "about-module--aboutContainer--2yg04";
export var bigImage = "about-module--bigImage--2CYUc";
export var bioCnt = "about-module--bioCnt--1UTSv";
export var bioGrid = "about-module--bioGrid--EF0Q8";
export var experienceBio = "about-module--experienceBio--5-Gd6";
export var educationBio = "about-module--educationBio--q_o9S";
export var spannedH3 = "about-module--spannedH3--2Afee";
export var halfWidth = "about-module--halfWidth--uDpCx";
export var downloadLink = "about-module--downloadLink--2yRyn";
export var labelCnt = "about-module--labelCnt--2RhDT";
export var bioElement = "about-module--bioElement--3kaQU";